
import { Component, Vue } from 'vue-property-decorator'
import { PlantMesses, PlantInfo } from '@/types/plantEstimation'
import PlantEstimationActual from './Actual.vue'
import { removeSpecial } from '@/utils/formatData'
@Component({
  components: { PlantEstimationActual }
})
export default class PlantEstimationAdd extends Vue {
  private loading = false
  private showDialog = false
  private formInfo: PlantInfo = {
    projectId: '',
    engineeringName: '',
    engineeringNum: '',
    engineeringArea: '',
    estimatedSum: '',
    resourceList: [],
    pictureList: []
  }

  private tableData: PlantMesses[] = []

  created () {
    this.getDetail()
  }

  get id () {
    return this.$route.params.id as string
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.plantEstimated.selectPlantEstimatedByEstimatedId,
      {
        estimatedId: this.id
      }).then((res) => {
      this.loading = false
      this.formInfo = res.plantEstimated || {}
      this.tableData = res.plantMessList || []
    })
  }

  // 录入实种量
  onImport () {
    this.showDialog = true
  }

  // 更新实种量
  updateInfo () {
    this.showDialog = false
    this.getDetail()
  }

  // 下载
  downloadFile () {
    const data = this.formInfo.resourceList[0].fileName.split('.')
    const fileName = removeSpecial(data[0]) + '.' + data[1]
    return `${this.formInfo.resourceList[0].filePrefix + this.formInfo.resourceList[0].fileUrl}?attname=${fileName}`
  }

  // 合计
  getSummaries () {
    return ['合计', null, null, null, this.formInfo.estimatedSum, this.formInfo.actualSum]
  }
}
